import React, { useEffect, useMemo, useState } from "react";
import Terminal, { ColorMode, TerminalInput, TerminalOutput } from 'react-terminal-ui';
import './index.css';
import RejjiePic from './images/rejjie.webp';
import BreakfastRoadPic from './images/breakfastroad.webp';
import JasdaCarePic from './images/jasdacare.webp';
import Petespace from './images/petespace.png';
import Deluxe from './images/deluxe.webp';
import AllahAleik from './images/allahaleik.jpg';
import Atp from './images/atp.jpg';
import Munch from './images/munch.jpg';
import Violet from './images/violet.jpg';

function App() {
  const [colorMode, setColorMode] = useState(ColorMode.Dark);
  const [lineData, setLineData] = useState([
    <TerminalOutput><span className="ascii"> ________  _______  _________  _______   ________          ________         <br />|\   __  \|\  ___ \|\___   ___\\  ___ \ |\   __  \        |\   ___ \        <br />\ \  \|\  \ \   __/\|___ \  \_\ \   __/|\ \  \|\  \       \ \  \_|\ \       <br /> \ \   ____\ \  \_|/__  \ \  \ \ \  \_|/_\ \   _  _\       \ \  \ \\ \      <br />  \ \  \___|\ \  \_|\ \  \ \  \ \ \  \_|\ \ \  \\  \|       \ \  \_\\ \ ___ <br />   \ \__\    \ \_______\  \ \__\ \ \_______\ \__\\ _\        \ \_______\\__\<br />    \|__|     \|_______|   \|__|  \|_______|\|__|\|__|        \|_______\|__|<br />                                                                            </span></TerminalOutput>,
    <TerminalOutput><img src={Petespace} className="mobile-ascii" /></TerminalOutput>,
    <TerminalOutput>╔══════════════════════════════╗</TerminalOutput>,
    <TerminalOutput>║     Peter Deghaim v1.0.2     ║</TerminalOutput>,
    <TerminalOutput>║ (C) 2024 ALL RIGHTS RESERVED ║</TerminalOutput>,
    <TerminalOutput>╚══════════════════════════════╝</TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput> ═══════APPLICATION MENU═══════ </TerminalOutput>,
    <TerminalOutput><a onClick={handleProjects} className="menu-option">  ╔══════════════════════════╗<br />  ║      (1) PROJECTS        ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a onClick={handleAbout} className="menu-option">  ╔══════════════════════════╗<br />  ║        (2) ABOUT         ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a onClick={handleContact} className="menu-option" target="_blank">  ╔══════════════════════════╗<br />  ║       (3) CONTACT        ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput>Click or type an option to continue...</TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
  ]);

  const [menu, setMenu] = useState([
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput> ═══════APPLICATION MENU═══════ </TerminalOutput>,
    <TerminalOutput><a onClick={handleProjects} className="menu-option">  ╔══════════════════════════╗<br />  ║      (1) PROJECTS        ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a onClick={handleAbout} className="menu-option">  ╔══════════════════════════╗<br />  ║        (2) ABOUT         ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput><a onClick={handleContact} className="menu-option" target="_blank">  ╔══════════════════════════╗<br />  ║       (3) CONTACT        ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
  ]);

  function handleMenu() {
    let ld = [<TerminalInput>menu</TerminalInput>];
    ld.push(...menu);
    setLineData((lineData) => [...lineData, ...ld]);
  }

  const [about, setAbout] = useState([
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput>Hi, my name is Peter! I'm a passionate Software Engineer with</TerminalOutput>,
    <TerminalOutput>over four years of experience in the industry. I have a strong</TerminalOutput>,
    <TerminalOutput>passion for user experience, design, art and customer satisfaction.</TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><a onClick={handleMenu} className="menu-option">  ╔══════════════════════════╗<br />  ║     (0) BACK TO MENU     ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
  ]);

  function handleAbout() {
    let ld = [<TerminalInput>about</TerminalInput>];
    ld.push(...about);
    setLineData((lineData) => [...lineData, ...ld]);
  }

  const [projects, setProjects] = useState([
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><img src={BreakfastRoadPic} className="website-pic" /></TerminalOutput>,
    <TerminalOutput><span className="wrap">Breakfast Road - Custom LinkTree style landing page</span></TerminalOutput>,
    <TerminalOutput><a href="https://breakfastroad.com/">https://breakfastroad.com</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><img src={RejjiePic} className="website-pic" /></TerminalOutput>,
    <TerminalOutput><span className="wrap">ByRejjie - MacOS inspired storefront and archive</span></TerminalOutput>,
    <TerminalOutput><a href="https://byrejjie.co/">https://byrejjie.co</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><a onClick={handleMenu} className="menu-option">  ╔══════════════════════════╗<br />  ║     (0) BACK TO MENU     ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
  ]);

  function handleProjects() {
    let ld = [<TerminalInput>projects</TerminalInput>];
    ld.push(...projects);
    setLineData((lineData) => [...lineData, ...ld]);
  }


  const [contact, setContact] = useState([
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput>Feel free to reach me at</TerminalOutput>,
    <TerminalOutput><a href="mailto:peter@peterdeghaim.com">peter@peterdeghaim.com</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput><a onClick={handleMenu} className="menu-option">  ╔══════════════════════════╗<br />  ║     (0) BACK TO MENU     ║<br />  ╚══════════════════════════╝</a></TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
  ]);

  function handleContact() {
    let ld = [<TerminalInput>contact</TerminalInput>];
    ld.push(...contact);
    setLineData((lineData) => [...lineData, ...ld]);
  }

  function onInput(input: string) {
    let ld = [...lineData];
    ld.push(<TerminalInput>{input}</TerminalInput>);
    if (input.toLocaleLowerCase().trim() === '1' || input.toLocaleLowerCase().trim() === 'about') {
      ld.push(...about);
    } else if (input.toLocaleLowerCase().trim() === '2' || input.toLocaleLowerCase().trim() === 'projects') {
      ld.push(...projects);
    } else if (input.toLocaleLowerCase().trim() === '3' || input.toLocaleLowerCase().trim() === 'reflections' || input.toLocaleLowerCase().trim() === 'reflection') {
      window.open('/reflections.pdf', '_blank')
    } else if (input.toLocaleLowerCase().trim() === '4' || input.toLocaleLowerCase().trim() === 'resume') {
      window.open('/resume.pdf', '_blank')
    } else if (input.toLocaleLowerCase().trim() === '5' || input.toLocaleLowerCase().trim() === 'cover letter') {
      window.open('/cover-letter.pdf', '_blank');
    } else if (input.toLocaleLowerCase().trim() === 'menu' || input.toLocaleLowerCase().trim() === 'help' || input.toLocaleLowerCase().trim() === '?' || input.toLocaleLowerCase().trim() === 'h' || input.toLocaleLowerCase().trim() === 'options' || input.toLocaleLowerCase().trim() === 'commands' || input.toLocaleLowerCase().trim() === 'main menu' || input.toLocaleLowerCase().trim() === 'mainmenu' || input.toLocaleLowerCase().trim() === '0') {
      ld.push(...menu);
    } else if (input.toLocaleLowerCase().trim() === 'clear') {
      ld = [];
    } else if (input) {
      ld.push(<TerminalOutput>Unrecognised command. Type 'menu' to redisplay options.</TerminalOutput>);
    }
    setLineData(ld);
  }


  return (
    <div className="container">
      <Terminal
        name='Peter Deghaim'
        colorMode={colorMode}
        onInput={onInput}
      >
        {lineData}
      </Terminal>
    </div>
  )
}

export default App;
